import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../routes";

function ContractedNavbar({ page }) {
  const openNav = () => {
    document.getElementById("mySidenav").style.width = "100%";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };
  return (
    <div className="nav-first">
      <div id="mySidenav" className="sidenav">
        <a href="#" className="closebtn" onClick={closeNav}>
          &times;
        </a>
        <Link
          to={routes.homePage}
          className={`${page === "Home" ? "active" : ""}`}
        >
          Home
        </Link>
        <Link
          to={routes.about}
          className={`${page === "About" ? "active" : ""}`}
        >
          ABOUT
        </Link>
        <Link to={routes.work} className={`${page === "Work" ? "active" : ""}`}>
          WORK
        </Link>
        <Link
          to={routes.services}
          className={`${page === "Services" ? "active" : ""}`}
        >
          SERVICES
        </Link>
        <Link
          to={routes.contact}
          className={`${page === "Contact" ? "active" : ""}`}
        >
          CONTACT
        </Link>
      </div>
      <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={openNav}>
        &#9776;
      </span>
    </div>
  );
}

export default ContractedNavbar;
