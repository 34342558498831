import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContractedNavbar from "../components/ContractedNavbar";

function About() {
  return (
    <>
      <ContractedNavbar page={"About"}></ContractedNavbar>
      <div class="main-header bg-dark h-auto">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <Navbar page={"About"}></Navbar>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-page">
        <div class="container">
          <div class="row">
            <div class="text">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="about-us-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="text-box">
                <p>
                  You Love your website, Your colleagues love that too but your
                  customers dont like it as they cant find it. Is your website
                  one of them? No Worries, we will make it visible...
                </p>
                <p>
                  We are an Integrated Marketing Agency with an International
                  Perspective born in 2009.
                </p>
                <p>We take your brand to New Horizons</p>
                <p>
                  We have grown alongside our clients and help established
                  brands and consumer startups win through marketing, technology
                  and digital space.{" "}
                </p>
                <p>
                  Galaxy Marketing is a Digital Communications and Marketing
                  Expertise Agency and our Recipe for Success is based on our 3
                  step approach. backed by our team strategy.
                </p>
              </div>
              <div class="inner-text1">
                <p>
                  <strong>Strategy - </strong>develop the right strategy for the
                  right target markets using our expertise in digital marketing.
                </p>
                <p>
                  <strong>Creativity - </strong>Creativity is essential for
                  Success, with this ability we can help reach your goal
                </p>
                <p>
                  <strong>Technology - </strong> We use a combination of graphic
                  design, social media, blogging, video and infographics to help
                  amplify your voice and reach new audiences in different ways.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="img-box">
                <img
                  class="img-fluid"
                  src="images/campaign-unsplash.jpg"
                  alt="campaign"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="our-story-section">
        <div class="text">
          <h3>Our Story</h3>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="paregraph-text">
                <p>
                  While working as a digital marketing expertise for the Law
                  firms in the UK While handling the digital marketing
                  department for various Law firm across UK, we realized that
                  there was a growing potential to drive business by using a
                  combination of SEO, Content Marketing, Videos, Social Media,
                  PPC
                </p>
                <p>
                  We take your brand to New Horizons. Galaxy Marketing is a
                  Digital Communications and Marketing Expertise Agency and our
                  Recipe for Success is based on our 3 step approach. backed by
                  our team strategy. Strategy, Creativity- Technology. We use a
                  combination of graphic design, social media, blogging, video
                  and infographics to help amplify your voice and reach new
                  audiences in different ways.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default About;
