import { Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage";
import About from "./Pages/About";
import ContractedNavbar from "./components/ContractedNavbar";
import Work from "./Pages/Work";
import Services from "./Pages/Services";
import Contact from "./Pages/Contact";
import { routes } from "./routes";
import Popup from "./components/Popup";
import React, { useState, useEffect } from "react";

function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const hasSubmitted = localStorage.getItem("formSubmitted");
    if (!showPopup && !hasSubmitted) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 10000); // Show the popup again after 10 seconds

      return () => clearTimeout(timer); // Clear timeout if the component unmounts
    }
  }, [showPopup]);

  return (
    <div className="App">
      <Popup show={showPopup} onClose={() => setShowPopup(false)} />
      <Routes>
        <Route path={routes.homePage} element={<HomePage />} />
        <Route path={routes.about} element={<About />} />
        <Route path={routes.work} element={<Work />} />
        <Route path={routes.services} element={<Services />} />
        <Route path={routes.contact} element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
