import React from "react";
import ContractedNavbar from "../components/ContractedNavbar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Services() {
  return (
    <>
      <ContractedNavbar page={"Services"}></ContractedNavbar>
      <div className="main-header bg-dark h-auto">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Navbar page={"Services"}></Navbar>
            </div>
          </div>
        </div>
      </div>

      <div className="services service_page">
        <div className="heading">
          <h1>Services</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/serviceimg-2.jpg"
                  alt="serviceimg-2"
                />
              </div>
              <div className="inner-text">
                <h3>Ecommerce Website Development</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/serviceimg-3.jpg"
                  alt="service"
                />
              </div>
              <div className="inner-text">
                <h3>Mobile Application</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/serviceimg-4.jpg"
                  alt="service"
                />
              </div>
              <div className="inner-text">
                <h3>Customized Website Designing</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/serviceimg-4.jpg"
                  alt="service"
                />
              </div>
              <div className="inner-text">
                <h3>Customized Software Development</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/damian-zaleski-RYyr-k3Ysqg-unsplash.jpg"
                  alt="service"
                />
              </div>
              <div className="inner-text">
                <h3>Domain & Hosting</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/mor-pankh-4.jpg"
                  alt="service"
                />
              </div>
              <div className="inner-text">
                <h3>Digital Marketing</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/nisonco-pr-and-seo-40Ji24OuUeQ-unsplash.jpg"
                  alt="service"
                />
              </div>
              <div className="inner-text">
                <h3>SEO</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/serviceimg-4.jpg"
                  alt="serviceimg-4"
                />
              </div>
              <div className="inner-text">
                <h3>SMO</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/brett-jordan-B_j4LJbam5U-unsplash.jpg"
                  alt="brett-jordan-B_j4LJbam5U-unsplash"
                />
              </div>
              <div className="inner-text">
                <h3>Google Adwords</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/bench-accounting-nvzvOPQW0gc-unsplash.jpg"
                  alt="bench-accounting-nvzvOPQW0gc-unsplash"
                />
              </div>
              <div className="inner-text">
                <h3>Re-Marketing</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/andrew-neel-ute2XAFQU2I-unsplash.jpg"
                  alt="andrew-neel-ute2XAFQU2I-unsplash"
                />
              </div>
              <div className="inner-text">
                <h3>Online Branding</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/christian-wiediger-ZYLmudR28SA-unsplash.jpg"
                  alt="christian-wiediger-ZYLmudR28SA-unsplash"
                />
              </div>
              <div className="inner-text">
                <h3>Youtube Channel Promotion</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Services;
