import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../routes";

function Navbar({ page }) {
  return (
    <nav className="navbar mt-3 navbar-expand-lg navbar-white">
      <div className="container-fluid">
        <Link to={routes.homePage}>
          <img
            className="img-fluid"
            src="images/logo-galaxy-2.png"
            alt="logo"
          />
        </Link>
        <button
          className="navbar-toggler nav-second-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="nav-second collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                to={routes.homePage}
                className={`nav-link ${page === "Home" ? "active" : ""}`}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.about}
                className={`nav-link ${page === "About" ? "active" : ""}`}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.work}
                className={`nav-link ${page === "Work" ? "active" : ""}`}
              >
                Work
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.services}
                className={`nav-link ${page === "Services" ? "active" : ""}`}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.contact}
                className={`nav-link ${page === "Contact" ? "active" : ""}`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
