import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContractedNavbar from "../components/ContractedNavbar";
import { Link } from "react-router-dom";
import { routes } from "../routes";

function HomePage() {
  return (
    <>
      <ContractedNavbar page={"Home"}></ContractedNavbar>
      <div className="main-header">
        {/* <img id="background-video" src="./videos/video-gif.gif"></img> */}
        <video id="background-video" autoPlay loop muted>
          <source src="./videos/video.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Navbar page={"Home"}></Navbar>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-white">
              <div className="main-heading">
                <h1>Targeted Converged Media</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center down-arrow">
              <a className="smoothScroll" href="#digital-agency-section">
                <img
                  className="img-fluid"
                  src="images/down-arrows.png"
                  alt="down-arrow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="digital-agency-section text-center"
        id="digital-agency-section"
      >
        <div className="container">
          <h2>
            The Digital Agency <br />
            You Call in a Fight
          </h2>
          <p>
            {/* <strong> */}
            Campaigners who know strategy and creatives who think like real
            people. Experienced fighters with a culture that thrives on success.
            Everything starts with data. Everything ends with victory. galaxy
            digital ensures your marketing is professional, consistent,
            personalized & at a lower cost than with do-it-yourself tools. You
            can focus on your business activities and we take care of your
            marketing. All this at a lower cost than do-it-yourself tools
            {/* </strong> */}
          </p>
          <div className="row"></div>
        </div>
        <Link to={routes.contact} className="btn btn1">
          GET A DEMO
        </Link>
      </div>

      <div className="digital-process-section">
        <div className="container">
          <h3 className="text-center mb-5">
            Learn About the Fight To Top{" "}
            <img src="images/arrow-right.png" alt="right-arrow" />{" "}
          </h3>
        </div>
      </div>

      <div className="work-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="img-box">
                <img
                  className="img-fluid"
                  src="images/responsive-img.png"
                  alt="work"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 position-relative text-box-1 our-work">
              <div className="text-box">
                <h3>Our Work</h3>
                <p>
                  <strong>Winning Adwards and Fights</strong>
                </p>
                <Link className="btn" to={routes.work}>
                  Check It Out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="pricing--style1">
        <div className="container">
          <h2>Pricing</h2>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 box--1 box--left">
              <div className="pricing--header">
                <div className="heading best">What You Get</div>
              </div>
              <div className="divider"></div>
              <div className="contents pose">
                <ul>
                  <li>Mobile Optimized Website</li>
                  <li>Social Media Posting</li>
                  <li>Email Campaign Builder With Live Support</li>
                  <li>Content Created For You</li>
                  <li className="AB">
                    <span2>All in one app for</span2>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 box--highlight">
              <div className="pricing--header-highlight">
                <div className="heading best">Galaxy Marketing</div>
              </div>
              <div className="contents abs">
                <ul>
                  <li>
                    <span>{"\u2714"}</span>
                  </li>
                  <li>
                    <span>{"\u2714"}</span>
                  </li>
                  <li>
                    <span>{"\u2714"}</span>
                  </li>
                  <li>
                    <span>{"\u2714"}</span>
                  </li>
                  <li>
                    <span3>USD 150/month</span3>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 box--1 box--right">
              <div className="pricing--header">
                <div className="heading best">Everyone Else</div>
              </div>
              <div className="divider"></div>
              <div className="contents post">
                <ul>
                  <li>$14/Month</li>
                  <li>$29/Month</li>
                  <li>$299/Month</li>
                  <li>$300/Month</li>
                  <li className="guru">
                    <span3>USD 642/month</span3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="experts-section">
        <div className="container">
          <div className="row">
            <h2>What Experts Say</h2>
            <p>
              {/* <strong> */}
              These days, a strong online presence is everything. In fact 97% of
              consumers research local companies before buying from them. Which
              means that if you don't keep up your online presence professional
              and well designed you can lose out big time when people look
              elsewhere first just because they're not familiarized enough yet
              with what's available around town...or country
              {/* </strong> */}
            </p>
          </div>
        </div>
      </div>

      <section className="services">
        <div className="row">
          <div
            className="col-md-4 title-area"
            style={{ backgroundImage: "url(images/service-detail-bg.jpg)" }}
          >
            <div className="title">
              <h2>Services</h2>
            </div>
          </div>

          <div className="col-md-8 service-list">
            <div className="row">
              <div className="col-sm-6 service-item">
                <img src="images/serviceimg-1.jpg" alt="service" />
                <div className="content">
                  <h3> Ecommerce Website </h3>
                </div>
              </div>
              <div className="col-sm-6 service-item">
                <img src="images/serviceimg-2.jpg" alt="service" />
                <div className="content">
                  <h3>Social Media Management</h3>
                </div>
              </div>
              <div className="col-sm-6 service-item">
                <img src="images/serviceimg-3.jpg" alt="service" />
                <div className="content">
                  <h3>Brand Building</h3> 
                </div>
              </div>
              <div className="col-sm-6 service-item">
                <img src="images/serviceimg-4.jpg" alt="service" />
                <div className="content">
                  <h3>Digital Advertising</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="analytics">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="analytics-social text-center">
                <img
                  src="images/push-social-logo copy.png"
                  className="img-fluid"
                  width="300"
                  height="278"
                  alt="logo"
                />
              </div>
              <div className="analytics-content">
                <h1>SOCIAL MEDIA MARKETING HAS NEVER BEEN EASIER</h1>
                <p>
                  Grow your business with a powerful, easy to use all-in-one
                  social media management platform.
                </p>
              </div>
              <div className="text-center">
                <Link className="btn">Learn More</Link>
              </div>
            </div>
            <div className="col-md-7">
              <img
                className="img-fluid"
                src="images/analytics-tablet copy3.jpg"
                alt="analytics"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="audience finding-agency img-fluid"
        style={{ backgroundImage: "url(images/banner3.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="finding-agency-box">
              <div className="col finding-agency-content">
                <h1>
                  FINDING AN AGENCY WHO UNDERSTANDS YOUR DIGITAL
                  <br /> NEEDS IS HARD.
                </h1>
                <p>
                  Let our amazing team help you craft your digital strategy
                  today
                </p>
                <Link to={routes.contact}>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="big-boxes">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className=" you-tube">
                <div className="heading1">
                  <h5>Youtube Video Promotion </h5>
                </div>
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="images/download (1).png"
                    alt="youtube"
                  />
                </div>
                <div className="inner-text">
                  <p>
                    World is going visual and people are spending more time on
                    Youtube watching videos especially during Covid, Powering
                    your brand & making it grow on Youtube has done wonders for
                    many practices & business. This is called smart delivery of
                    a message to a larger group of audience.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className=" you-tube">
                <div className="heading1">
                  <h5>Search Engine Optimization </h5>
                </div>
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="images/download.png"
                    alt="download"
                  />
                </div>
                <div className="inner-text">
                  <p>
                    We know how to fight it to the top since more than a decade
                    now...No more flipping of pages to present your website to
                    the target audience... Social Media - Engage and become
                    friends with your clients. Social media is a crucial
                    component of your marketing matrix.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className=" you-tube">
                <div className="heading1">
                  <h5>Digital Advertising Management</h5>
                </div>
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="images/images.png"
                    alt="social-media"
                  />
                </div>
                <div className="inner-text">
                  <p>
                    It doesn't matter if you are an established company looking
                    to gain more market share, every business is battling for
                    consumer footfalls or impressions. Our digital advertising
                    services will give you the competitive advantage you need to
                    drive growth instantly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default HomePage;
