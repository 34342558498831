import React from "react";
import { routes } from "../routes";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div
      className="home-footer img-fluid"
      style={{ backgroundImage: "url(images/nasa-Q1p7bh3SHj8-unsplash.jpg)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-3 footer-navigation">
            <h2>Navigation</h2>
            <ul>
              <li>
                <Link to={routes.about}>Home</Link>
              </li>
              <li>
                <Link to={routes.about}>About Us</Link>
              </li>
              <li>
                <Link to={routes.work}>Work</Link>
              </li>
              <li>
                <Link to={routes.services}>Services</Link>
              </li>
              <li>
                <Link to={routes.contact}>Contact</Link>
              </li>
            </ul>
          </div>

          <div className="col-md-3 footer-services">
            <h2>Services</h2>
            <ul>
              <li>
                <a href="#">Ecommerce Website Development</a>
              </li>
              <li>
                <a href="#">Mobile Application</a>
              </li>
              <li>
                <a href="#">Customized Website Designing</a>
              </li>
              <li>
                <a href="#">Customized Software Development</a>
              </li>
              <li>
                <a href="#">Domain & Hosting</a>
              </li>
              <li>
                <a href="#">Digital Marketing</a>
              </li>
              <li>
                <a href="#">Search Engine Optimization</a>
              </li>
              <li>
                <a href="#">SMO</a>
              </li>
              <li>
                <a href="#">Google Adwords</a>
              </li>
              <li>
                <a href="#">Re-Marketing</a>
              </li>
              <li>
                <a href="#">Online Branding</a>
              </li>
              <li>
                <a href="#">Youtube Channel Promotion</a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 footer-social">
            <h2>Follow Us</h2>
            <ul>
              <li>
                <a href="#">
                  <i className="bi bi-facebook"></i>
                  <span>facebook</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-twitter"></i>
                  <span>twitter</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-instagram"></i>
                  <span>instagram</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-linkedin"></i>
                  <span>linkedin</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-youtube"></i>
                  <span>youtube</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 footer-contact">
            <h2>Contact</h2>
            <div className="mobile">
              <h5>Phone</h5>
              <img src="images/phone-call-icon.png" alt="phone" />
              <a href="#">+919873083334,</a>
              <a href="#">+919958525299</a>
              <br />
              <img src="images/phone-call-icon.png" alt="phone" />
              <span>UK - </span>
              <a href="#">0044 203 129 4600 </a>
              <br />
              <img src="images/phone-call-icon.png" alt="phone" />
              <span>US - </span>
              <a href="#">001 510 613 4604</a>
            </div>
            <div className="email">
              <h5>Email</h5>
              <img src="images/email-icon.png" alt="email" />
              <a href="#">kumarsharmaakshay@gmail.com</a>
            </div>
            <div className="address">
              <h5>Address</h5>
              <div className="box2">
                <img src="images/address-location-icon.png" alt="address" />
                <p>Emca House, Ansari Road, Daryaganj, New Delhi 110002</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="copy-right">
            <p>
              <span> © 2022</span>{" "}
              <a href="https://galaxymarketing.in">GALAXY MARKETING</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
