import React, { useState } from "react";
import "./Popup.css"; // Assuming you have some basic CSS

function Popup({ show, onClose }) {
  const [mobileNumber, setMobileNumber] = useState("");

  const [formData, setFormData] = useState({
    day: "",
    time: "",
    phone: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    // Create the request payload from formData
    const payload = {
      day: formData.day,
      time: formData.time,
      phone: formData.phone,
    };

    const host = process.env.REACT_APP_API_URL;
    // Use the Fetch API to send the data to the server
    fetch(`${host}/requestcallback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setLoading(false);
        setMessage("Success: Callback request submitted successfully!");
        localStorage.setItem("formSubmitted", true);
        setFormData({ day: "", time: "", phone: "" });
        setTimeout(onClose, 2500); // Close the popup after submission
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Error: Failed to submit the callback request.");
      });
  };

  if (!show) {
    return null;
  }

  return (
    <div id="requestPopup" className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <button className="popup-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-body">
          <h4>Would you like us to call you in 3 seconds?</h4>
          <form
            onSubmit={handleSubmit}
            name="CenterPopupCallabackForm"
            id="CenterPopupCallabackForm"
          >
            <div className="form-group">
              <div className="day_time">
                <div className="cbt_form_control cbt_form_day">
                  <select
                    tabIndex="0"
                    id="cbt_call_def_date31811832605899837"
                    name="day"
                    value={formData.day}
                    onChange={handleChange}
                  >
                    <option value="Monday" day="5">
                      Monday
                    </option>
                    <option value="Tuesday" day="6">
                      Tuesday
                    </option>
                    <option value="Wednesday" day="0">
                      Wednesday
                    </option>
                    <option value="Thursday" day="1">
                      Thursday
                    </option>
                    <option value="Friday" day="2">
                      Friday
                    </option>
                    <option value="Saturday" day="3">
                      Saturday
                    </option>
                    <option value="" day="4">
                      Sunday
                    </option>
                  </select>
                </div>
                <div className="cbt_form_control cbt_form_time">
                  <select
                    tabIndex="0"
                    id="cbt_call_def_time31811832605899837"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                  >
                    <option value="4:00 pm" data-date="2022-11-5">
                      4:00 pm
                    </option>
                    <option value="4:15 pm" data-date="2022-11-5">
                      4:15 pm
                    </option>
                    <option value="4:30 pm" data-date="2022-11-5">
                      4:30 pm
                    </option>
                    <option value="4:45 pm" data-date="2022-11-5">
                      4:45 pm
                    </option>
                    <option value="5:00 pm" data-date="2022-11-5">
                      5:00 pm
                    </option>
                    <option value="5:15 pm" data-date="2022-11-5">
                      5:15 pm
                    </option>
                    <option value="5:30 pm" data-date="2022-11-5">
                      5:30 pm
                    </option>
                    <option value="5:45 pm" data-date="2022-11-5">
                      5:45 pm
                    </option>
                    <option value="6:00 pm" data-date="2022-11-5">
                      6:00 pm
                    </option>
                    <option value="6:15 pm" data-date="2022-11-5">
                      6:15 pm
                    </option>
                    <option value="6:30 pm" data-date="2022-11-5">
                      6:30 pm
                    </option>
                    <option value="6:45 pm" data-date="2022-11-5">
                      6:45 pm
                    </option>
                    <option value="7:00 pm" data-date="2022-11-5">
                      7:00 pm
                    </option>
                    <option value="7:15 pm" data-date="2022-11-5">
                      7:15 pm
                    </option>
                    <option value="7:30 pm" data-date="2022-11-5">
                      7:30 pm
                    </option>
                    <option value="7:45 pm" data-date="2022-11-5">
                      7:45 pm
                    </option>
                    <option value="8:00 pm" data-date="2022-11-5">
                      8:00 pm
                    </option>
                    <option value="8:15 pm" data-date="2022-11-5">
                      8:15 pm
                    </option>
                    <option value="8:30 pm" data-date="2022-11-5">
                      8:30 pm
                    </option>
                  </select>
                </div>
              </div>
              <div className="cbt_form_box_inn">
                <span className="cbt_flag">
                  <img
                    src="https://app.callbacktracker.com/widget/flags/in.png"
                    alt="IN Flag"
                  />
                </span>
                <input
                  type="tel"
                  id="call_back_def-phone31811832605899837"
                  name="phone"
                  className="call_back_input-phone"
                  maxLength="22"
                  placeholder="Enter your phone number"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                  style={{
                    margin: 10,
                    padding: 5,
                    width: "auto",
                    borderColor: "rgb(255, 255, 255)",
                    color: "rgb(77, 77, 77)",
                  }}
                />
              </div>
            </div>
            {/* Loading Indicator */}
            {loading && <div className="loading">Submitting...</div>}
            {/* Submission Message */}
            {message && <div className="message">{message}</div>}
            <div className="modal-footer">
              <button
                type="submit"
                id="CenterPopupFormSubmit"
                className="submit-button"
              >
                Request a Callback
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Popup;
