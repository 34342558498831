import React, { useState } from "react";
import Navbar from "../components/Navbar";
import ContractedNavbar from "../components/ContractedNavbar";
import Footer from "../components/Footer";
import Popup from "../components/Popup";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const [formStatus, setFormStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const host = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${host}/contactus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          phone: formData.phone,
        }),
      });

      if (response.status === 200) {
        setFormStatus("Message sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      }
    } catch (error) {
      setFormStatus("Failed to send message. Please try again later.");
    }
  };

  return (
    <>
      <ContractedNavbar page={"Contact"}></ContractedNavbar>
      <Popup></Popup>
      <div className="main-header about h-auto">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Navbar page={"Contact"}></Navbar>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-page">
        <div className="container">
          <div className="row">
            <div className="text">
              <h1>Contact Galaxy Marketing</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6 left-col">
              <div className="text-box">
                <h2></h2>
                <div
                  id="form_mssg"
                  className="alert alert-success text-center"
                  role="alert"
                  style={{ display: formStatus ? "" : "none" }}
                >
                  {formStatus && <p>{formStatus}</p>}
                </div>
                <form className="needs-validation" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mt-3"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control mt-3"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mt-3"
                      id="phone"
                      name="phone"
                      placeholder="Contact Number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control mt-3 mb-3"
                      id="message"
                      rows="3"
                      name="message"
                      placeholder="How can we help you?"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn1">
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-6 my-auto left-col">
              <div className="text-box">
                <ul className="list-group">
                  <li className="list-group-item">
                    <h5>Phone</h5>
                    <a href="#">+919873083334,</a>
                    <a href="#">+919958525299</a>
                    <br />
                    <strong>UK: </strong>
                    <a href="#">0044 203 129 4600 </a>
                    <br />
                    <strong>US: </strong>
                    <a href="#">001 510 613 4604</a>
                  </li>
                  <li className="list-group-item">
                    <h5>Email</h5>
                    <div className="box4">
                      <p>
                        <strong>Sales: </strong>
                        {/* <a target="_blank" href="sales@galaxy"> */}
                        sales@galaxymarketing.in
                        {/* </a> */}
                      </p>
                      <p>
                        <strong>Support: </strong>
                        {/* <a target="_blank" href="support @galaxy"> */}
                        support@galaxymarketing.in
                        {/* </a> */}
                      </p>
                      <p>
                        <strong>Enterprise: </strong>
                        {/* <a target="_blank" href="enterprise@galaxy"> */}
                        enterprise@galaxymarketing.in
                        {/* </a> */}
                      </p>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <h5>Address</h5>
                    <p>Emca House, Ansari Road, Daryaganj, New Delhi 110002</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map-section">
        <div className="container">
          <p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.586203829636!2d77.24110951494836!3d28.642161490396337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd85d481b289%3A0x61755a57e2bfa5ca!2sGalaxy%20Marketing!5e0!3m2!1sen!2sin!4v1667909702295!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Contact;
