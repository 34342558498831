import React from "react";
import Navbar from "../components/Navbar";
import ContractedNavbar from "../components/ContractedNavbar";
import Footer from "../components/Footer";

function Work() {
  return (
    <>
      <ContractedNavbar page={"Work"}></ContractedNavbar>
      <div className="main-header bg-dark h-auto">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Navbar page={"Work"}></Navbar>
            </div>
          </div>
        </div>
      </div>

      <section className="our-work-page">
        <div className="heading">
          <h1>Our Work</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="our-work-list">
              <div className="row">
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img1.jpg" alt="service" />
                  <div className="content">
                    <h3>Wheldon Law</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img2.jpg" alt="service" />
                  <div className="content">
                    <h3>Stocks Solicitors</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/serviceimg-1.jpg" alt="service" />
                  <div className="content">
                    <h3>Mackesys</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img4.jpg" alt="service" />
                  <div className="content">
                    <h3>Salam Solicitors</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img5.jpg" alt="service" />
                  <div className="content">
                    <h3>ICS Legals</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img6.jpg" alt="service" />
                  <div className="content">
                    <h3>leslie and co solicitors</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img7.jpg" alt="service" />
                  <div className="content">
                    <h3>IVF Hospital</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img8.jpg" alt="service" />
                  <div className="content">
                    <h3>Cosmetic Surgeons</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img9.jpg" alt="service" />
                  <div className="content">
                    <h3>Hair Transplants</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img10.jpg" alt="service" />
                  <div className="content">
                    <h3>Cosmetic Surgeon UAE</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img11.jpg" alt="service" />
                  <div className="content">
                    <h3>Insurance USA</h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 work-item">
                  <img src="images/our-page-img11.jpg" alt="service" />
                  <div className="content">
                    <h3>Insurance USA</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-web-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="content">
                <div className="inner-heading">
                  <h1>Website</h1>
                </div>
                <div className="img-box">
                  <img src="images/website-icon.png" alt="website-icon" />
                </div>
                <ul>
                  <li>
                    <span>{"\u2714"}</span> 100 pager detailed website
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Click and learn modules
                  </li>
                  <li>
                    <span>{"\u2714"}</span> 8 blogs per month
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Onpage SEO
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Map maltipal office locations
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="content">
                <div className="inner-heading">
                  <h1>Content Bank</h1>
                </div>
                <div className="img-box">
                  <img
                    src="images/web-content-icon.png"
                    alt="web-content-icon"
                  />
                </div>
                <ul>
                  <li>
                    <span>{"\u2714"}</span> Covers wide content
                  </li>
                  <li>
                    <span>{"\u2714"}</span> 500+ content
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Multipal content formats
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Engage existing prospects
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Never run out content
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="content">
                <div className="inner-heading">
                  <h1>Email</h1>
                </div>
                <div className="img-box">
                  <img src="images/email-address-icon.png" alt="email" />
                </div>
                <ul>
                  <li>
                    <span>{"\u2714"}</span> Automations existing customers
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Integrated with blogs
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Consistent client newsletters
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Personalised Branding
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Easy to understand analytics
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="content">
                <div className="inner-heading">
                  <h1>Social Media</h1>
                </div>
                <div className="img-box">
                  <img
                    src="images/social-media-marketing-icon.png"
                    alt="social-media"
                  />
                </div>
                <ul>
                  <li>
                    <span>{"\u2714"}</span> Automations
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Professionally designed content
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Educational content
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Use in Multipal social platform
                  </li>
                  <li>
                    <span>{"\u2714"}</span> Consistent social presence
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Work;
